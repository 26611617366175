<template>
  <v-sheet
    v-resize="fixHeight"
    class="virtual-data-table"
    tile
    width="100%"
    height="100%"
  >
    <slot :items="items" name="top" />
    <DataContainer
      ref="container"
      :height="Number(tableHeight)"
      :middle-width="middleWidth"
      :left-width="leftWidth"
      :data-height="48 * items.length + 48"
      header-height="56px"
    >
      <template
        v-for="{ slot, columns } in [
          { columns: leftColumns, slot: 'left-header' },
          { columns: middleColumns, slot: 'header' },
        ]"
      >
        <v-layout :slot="slot" :key="slot" class="head">
          <HeaderCell
            v-for="col in columns"
            :key="col.key"
            :column="col"
            :ordering="ordering"
            :style="{
              width: px(col.width),
              'max-width': px(col.width),
              'min-width': px(col.width),
            }"
            class="th"
            @order="$emit('update:ordering', $event)"
          >
            <slot :name="`header.${col.prop}`">{{ col.text }}</slot>
          </HeaderCell>
        </v-layout>
      </template>
      <template
        v-for="{ slot, columns } in [
          { columns: leftColumns, slot: 'left-body' },
          { columns: middleColumns, slot: 'body' },
        ]"
      >
        <RecycleScroller
          :slot="slot"
          :key="slot"
          :items="wrappedItems"
          class="scroller"
          size-field="size"
          key-field="key"
        >
          <template #default="props">
            <v-layout
              :class="{ tr: true, selected: props.item.selected }"
              @click="$emit('click-row', props.item)"
            >
              <v-layout
                v-for="col in columns"
                :key="col.key"
                :style="{
                  width: px(col.width),
                  'max-width': px(col.width),
                  'min-width': px(col.width),
                  height: px(props.item.size),
                }"
                align-center
                class="td"
              >
                <TableCell
                  :column="col"
                  :row="props.item"
                  class="text-truncate"
                />
              </v-layout>
            </v-layout>
          </template>
          <template v-if="!disableInfinite" #after>
            <v-layout style="height: 48px;" class="pl-5">
              <InfiniteLoading
                v-if="slot === 'body'"
                :identifier="identifier"
                force-use-infinite-wrapper=".scroller"
                @infinite="$emit('infinite', $event)"
              >
                <span slot="no-more" />
                <span slot="no-results">該当するデータはありません</span>
              </InfiniteLoading>
            </v-layout>
          </template>
        </RecycleScroller>
      </template>
    </DataContainer>

    <v-layout ref="footer" column>
      <v-flex>
        <span v-if="totalCount !== null">全 {{ totalCount | locale }}件 </span>
        <span v-if="!disableInfinite"> 読込済 {{ items.length }} 件 </span>
      </v-flex>
      <v-flex>
        <slot name="bottom" />
      </v-flex>
    </v-layout>
    <!-- colums -->
    <div style="display: none;"><slot /></div>
  </v-sheet>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import InfiniteLoading from 'vue-infinite-loading'

import DataContainer from './DataContainer'
import HeaderCell from './HeaderCell'
import TableCell from './_Cell'

function defaultKeyExtractor(item) {
  return item.id
}

function px(val) {
  return val + 'px'
}
export default {
  components: {
    RecycleScroller,
    InfiniteLoading,
    DataContainer,
    TableCell,
    HeaderCell,
  },
  filters: {
    locale(value) {
      return Number(value).toLocaleString()
    },
  },
  provide() {
    return {
      $_addColumn: this.addColumn,
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    identifier: {
      type: [String, Number, Object],
      default: null,
    },
    totalCount: {
      type: Number,
      default: null,
    },
    ordering: {
      type: String,
      default: '',
    },
    disableInfinite: {
      type: Boolean,
      default: false,
    },
    // 1行選択できるようにする
    singleSelect: {
      type: Boolean,
      default: false,
    },
    keyExtractor: {
      type: Function,
      default: defaultKeyExtractor,
    },
    value: {
      // selected rows
      type: Array,
      default: () => [],
    },
    height: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    columns: [],
    wrappedItems: [],
    tableHeight: 300,
  }),
  computed: {
    leftColumns() {
      return this.columns.filter(x => x.fixed === 'left')
    },
    middleColumns() {
      return this.columns.filter(x => !x.fixed)
    },
    rightColumns() {
      return this.columns.filter(x => x.fixed === 'right')
    },
    leftWidth() {
      return this.sumWidth(this.leftColumns)
    },
    middleWidth() {
      return this.sumWidth(this.middleColumns)
    },
    rightWidth() {
      return this.sumWidth(this.rightColumns)
    },
    selectedItems() {
      return this.wrappedItems.filter(x => x.selected)
    },
    // wrappedItems() {
    //   console.log('hoge wrap')
    //   const size = 48
    //   const keyExtractor = this.keyExtractor
    //   return this.items.map((item) => ({
    //     key: keyExtractor(item),
    //     selected: false,
    //     size,
    //     item,
    //   }))
    // },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.wrappedItems = this.wrapItems()
      },
    },
    value(arr) {
      // TODO: valueが変更された場合、selectedを更新する
      // console.log('?ch v', this.value)
      // const keyExtractor = this.keyExtractor
      // const keys = arr.map(keyExtractor)
      // this.wrappedItems.forEach((row) => {
      //   row.selected = keys.includes(row.key)
      // })
    },
  },
  created() {
    if (this.singleSelect) {
      this.$on('click-row', this.selectSingle)
    }
  },
  mounted() {
    // NOTE: レンダリング順の関係でslot=topが縦に長い場合にfooterが表示されないことおがある
    this.$nextTick(() => {
      this.fixHeight()
    })
  },
  updated() {
    // console.log('updated')
  },
  beforeDestroy() {
    this.$off('click-row', this.selectSingle)
  },
  methods: {
    px,
    fixHeight() {
      const rect = this.$refs.container.$el.getBoundingClientRect()
      if (rect) {
        const footRect = this.$refs.footer.getBoundingClientRect()
        // console.log(window.innerHeight, rect, footRect)
        this.tableHeight = window.innerHeight - rect.top - footRect.height
      }
    },
    wrapItems() {
      const size = 48
      const keyExtractor = this.keyExtractor
      // NOTE: valueで渡されたrowを選択済にする
      const selectedKeys = this.value.map(this.keyExtractor)
      return this.items.map(item => {
        const key = keyExtractor(item)
        const row = {
          key,
          selected: selectedKeys.includes(key),
          size,
          item,
          toggleSelect: () => {
            this.toggleSelect(row)
          },
        }
        return row
      })
    },
    addColumn(col) {
      this.columns.push(col)
    },
    sumWidth(cols) {
      return cols.map(x => x.width).reduce((a, b) => a + b, 0)
    },
    toggleSelect(row) {
      const key = row.key
      const keyExtractor = this.keyExtractor
      const idx = this.value.findIndex(x => keyExtractor(x) === key)
      if (idx < 0) {
        const arr = this.value.map(x => x)
        row.selected = true
        arr.push(row.item)
        this.$emit('input', arr)
      } else {
        const arr = this.value.map(x => x)
        row.selected = false
        arr.splice(idx, 1)
        this.$emit('input', arr)
      }
    },
    selectSingle(row) {
      const prevSelectedIdx = this.wrappedItems.findIndex(x => x.selected)
      const key = row.key
      const keyExtractor = this.keyExtractor
      const idx = this.wrappedItems.findIndex(x => keyExtractor(x.item) === key)
      if (prevSelectedIdx >= 0 && prevSelectedIdx !== idx) {
        const data = this.wrappedItems[prevSelectedIdx]
        data.selected = false
        this.wrappedItems.splice(idx, 1, data)
      }
      row.selected = !row.selected
      this.wrappedItems.splice(idx, 1, row)
      if (row.selected) this.$emit('input', [row.item])
      else this.$emit('input', [])
    },
  },
}
</script>

<style lang="scss" scoped>
.virtual-data-table {
  overflow: hidden;
}
.horizontal {
  height: 100%;
  overflow-x: auto;
}
.scroller {
  height: 100%;
  // overflow: hidden !important;
}
.head {
  font-weight: 500;
  font-size: 12px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  user-select: none;
  color: rgba(0, 0, 0, 0.54);
  height: 56px;
}
.th {
  padding: 0 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.tr.selected {
  background-color: #f5f5f5;
}
.td {
  white-space: nowrap;
  padding: 0 12px;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
