import _Vue from 'vue'

import ToastComponet from './Toast.vue'
import { Dictionary } from 'vue-router/types/router'

function getContainer() {
  let div = document.querySelector('.toasts')
  if (div) return div
  div = document.createElement('div')
  div.classList.add('toasts')
  document.querySelector('#app')?.appendChild(div)
  return div
}

export interface Toast {
  (text: string, opts?: Dictionary<any>): void
  info(text: string, opts?: Dictionary<any>): void
  success(text: string, opts?: Dictionary<any>): void
  error(text: string, opts?: Dictionary<any>): void
  warn(text: string, opts?: Dictionary<any>): void
}

const createToastFn = (root: Vue) => {
  const toast: Toast = (text, opts) => {
    const vm = new ToastComponet({
      propsData: {
        text,
        options: opts || null,
      },
      parent: root, // NOTE: v-snakbarにparentが必須
    })
    const div = getContainer()
    const el = document.createElement('div')
    div.appendChild(el)
    vm.$mount(el)
  }
  toast.info = (text, opts) => {
    toast(text, Object.assign({ color: 'info' }, opts || {}))
  }
  toast.success = (text, opts) => {
    toast(text, Object.assign({ color: 'success' }, opts || {}))
  }
  toast.error = (text, opts) => {
    toast(text, Object.assign({ color: 'error' }, opts || {}))
  }
  toast.warn = (text, opts) => {
    toast(text, Object.assign({ color: 'warning' }, opts || {}))
  }
  return toast
}

export default {
  install(Vue: typeof _Vue) {
    let toast: Toast | null = null
    Object.defineProperty(Vue.prototype, '$toast', {
      get() {
        if (!toast) toast = createToastFn(this.$root)
        return toast
      },
    })
  },
}
