import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'js-cookie'
// @ts-ignore
import { API } from '@/api.ts'
import { User } from './types/user'
Vue.use(Vuex)
const JWT_COOKIE_NAME = 'fufu admin jwt'

function createState(): { token: string | null; user: null | User } {
  return {
    token: null,
    user: null,
  }
}

declare module 'vuex' {
  /* eslint no-unused-vars: 0 */
  export interface Store<S> {
    $api: API
  }
}

export default new Vuex.Store({
  state: createState(),
  getters: {
    loggedIn: ({ token }) => Boolean(token),
    token: ({ token }) => token,
    user: ({ user }) => user,
    staffLoggedIn: ({ user }) => Boolean(user?.isStaff),
    superuserLoggedIn: ({ user }) => Boolean(user?.isSuperuser),
  },
  mutations: {
    LOGIN(state, { access, refresh, user }) {
      state.token = access
      state.user = user
      cookie.set(JWT_COOKIE_NAME, refresh)
    },
    CLEAR(state) {
      Object.assign(state, createState())
      cookie.remove(JWT_COOKIE_NAME)
    },
  },
  actions: {
    async login({ commit }, data) {
      commit('LOGIN', await this.$api.auth.obtainToken(data))
    },
    async refresh({ commit }) {
      let refresh = cookie.get(JWT_COOKIE_NAME)
      if (!refresh) return
      try {
        let data = await this.$api.auth.refreshToken({ refresh })
        commit('LOGIN', data)
      } catch (e: any) {
        // console.error(e)
        if (e.status === 401) commit('CLEAR')
      }
    },
    clear({ commit }) {
      commit('CLEAR')
    },
  },
})
