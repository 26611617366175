import axios, { AxiosError, AxiosStatic, AxiosInstance } from 'axios'
import qs from 'qs'
import { snakeProps } from 'change-prop-case'
import { User } from '@/types/user'
import { Customer } from '@/types/customer'
import {
  Timeline,
  OperationHistory,
  ReserveAailability,
  TimeCard,
  CancelPrice,
  Reservation,
  Information,
  Subscription,
  PurchaseHistory,
  Goods,
  Holiday,
  OpenDay,
} from './types'
import { Shop } from './types/shop'
import { ReservationLog } from './types/reservation'

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    post<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>
    put<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>
    patch<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>
  }
}
export interface HttpError extends AxiosError {
  status?: number
  statusCode?: number
}
export type PK = number | string

interface GetTokenFn {
  (): string | null
}

export type QueryParams = Record<string, unknown>
export type PostData = Record<string, unknown>
export interface Paginated<T> {
  next: null | string
  previous: null | string
  results: T[]
}

// error.statusでレスポンスステータスを取れるようにする
function setErrorStatus(error: HttpError) {
  if (error.response) {
    error.status = error.statusCode = error.response.status
  }
  return Promise.reject(error)
}

function createInstance(getToken: GetTokenFn, config: Record<string, unknown>) {
  let axiosInstance = axios.create(
    Object.assign(
      {
        paramsSerializer: (params: QueryParams) =>
          qs.stringify(snakeProps(params), { indices: false }),
      },
      config
    )
  )
  // set token interceptors
  axiosInstance.interceptors.response.use(res => res.data, setErrorStatus)
  axiosInstance.interceptors.request.use(
    config => {
      // NOTE: axios.defaultsを使用すると別ユーザに影響がでるため
      // interceptorでtokenをセットする
      let token = null
      if (typeof getToken === 'function') {
        token = getToken()
      }
      if (!token) return config
      if (token) config.headers.common['Authorization'] = 'JWT ' + token
      return config
    },
    err => Promise.reject(err)
  )
  return axiosInstance
}
export interface CursorPaginationResult<T = Record<string, unknown>> {
  previous: string | null
  next: string | null
  results: T[]
}
export type APIPromise<T = any> = Promise<T>
export declare interface API_ {
  axios: AxiosStatic
  http: AxiosInstance
  auth: {
    obtainToken(data: any): APIPromise<{ user: User; token: string }>
    refreshToken(data: any): APIPromise<{ user: User; token: string }>
  }
  customers(
    id?: PK
  ): {
    get(params?: QueryParams): APIPromise<Customer>
    list(params?: QueryParams): APIPromise<CursorPaginationResult<Customer>>
    count(params: QueryParams): APIPromise<number>
    create(data: QueryParams): APIPromise<Customer>
    update(data: QueryParams): APIPromise<Customer>
    partialUpdate(data: QueryParams): APIPromise<Customer>
  }
  counselingQuestions(
    id?: PK
  ): {
    get(params: QueryParams): APIPromise
    list(params: QueryParams): APIPromise
  }
  users(
    id?: PK
  ): {
    get(params: QueryParams): APIPromise
    list(params: QueryParams): APIPromise
    count(params: QueryParams): APIPromise
  }
  shops(
    id?: PK
  ): {
    get(params: QueryParams): APIPromise
    list(params?: QueryParams): APIPromise
    rejectCount(
      date: string
    ): {
      get(): APIPromise<number>
      inc(): APIPromise<number>
      dec(): APIPromise<number>
    }
    monthlyVisitors(date: string): APIPromise<{ date: string; count: number }[]>
    menus(
      id: PK
    ): {
      reservableTimes(
        date: string
      ): {
        get(params?: QueryParams): APIPromise<ReserveAailability[]>
      }
    }
  }
  reservations(
    id?: PK
  ): {
    cancelPrice(): APIPromise<CancelPrice>
    cancel(nocharge?: boolean): APIPromise
    delete(): APIPromise
    restore(): APIPromise

    logs(
      logId?: PK
    ): {
      get(params?: QueryParams): APIPromise
      list(params?: QueryParams): APIPromise
    }
  }
  kpis: {
    daily(date: string): APIPromise
    monthly(month: string): APIPromise
  }
  shifts(
    id?: PK
  ): {
    get(params: QueryParams): APIPromise
    list(params: QueryParams): APIPromise
    update(data: any): APIPromise
  }

  my: {
    cards(
      id?: PK
    ): {
      get(params?: QueryParams): APIPromise
      create(token: string): APIPromise
      delete(): APIPromise
      list(params?: QueryParams): APIPromise
    }
  }
}

const createAPI = (api: AxiosInstance) => {
  return {
    axios,
    http: api,
    auth: {
      obtainToken: (data: any) => api.post('auth/', data),
      refreshToken: (data: any) => api.post('auth/refresh/', data),
    },
    // account: {
    //   get: () => api.get('accounts/profile').then((data: object) => data.data),
    // },
    tickets: (id?: number) => ({
      get: (params?: QueryParams) => api.get(`tickets/${id}/`, { params }),
      list: (params?: QueryParams) => api.get('tickets/', { params }),
      add: (data: any) => api.post('tickets/add/', data),
    }),
    purchasedTickets: (id?: number) => ({
      get: (params?: QueryParams) =>
        api.get(`purchased_tickets/${id}/`, { params }),
      list: (params?: QueryParams) => api.get('purchased_tickets/', { params }),
      partialUpdate: (data: any) => api.patch(`purchased_tickets/${id}/`, data),
      delete: () => api.delete(`purchased_tickets/${id}/`),
    }),
    usedTickets: (id?: number) => ({
      get: (params?: QueryParams) => api.get(`used_tickets/${id}/`, { params }),
      list: (params?: QueryParams) => api.get('used_tickets/', { params }),
      create: (data: any) => api.post('used_tickets/', data),
      delete: () => api.delete(`used_tickets/${id}/`),
      count: (params?: QueryParams) =>
        api.get('used_tickets/count/', { params }),
    }),
    customers: (id?: PK) => ({
      get: (params?: QueryParams) => api.get(`customers/${id}/`, { params }),
      list: (params?: QueryParams) => api.get('customers/', { params }),
      count: (params?: QueryParams) => api.get('customers/count/', { params }),
      create: (data: any) => api.post('customers/', data),
      update: (data: any) => api.patch(`customers/${id}/`, data),
      partialUpdate: (data: any) => api.patch(`customers/${id}/`, data),
      registerEcSiteUser: (data: any) =>
        api.post(`customers/register_ec_user/`, data),
      merge: (data: any) => api.post(`customers/merge/`, data),
    }),
    counselingQuestions: (id: PK) => ({
      get: (params: QueryParams) =>
        api.get(`counseling_questions/${id}/`, { params }),
      list: (params: QueryParams) =>
        api.get('counseling_questions/', { params }),
    }),
    users: (id?: PK) => ({
      get: (params: QueryParams) => api.get(`user/${id}/`, { params }),
      list: (params: QueryParams) => api.get('user/', { params }),
      count: (params: QueryParams) => api.get('user/count/', { params }),
      obtainToken: () => api.get(`user/${id}/obtain_token/`), // for dev
    }),
    shops: (id?: PK) => ({
      get: (params: QueryParams): APIPromise<Shop> =>
        api.get(`shops/${id}/`, { params }),
      list: (params?: QueryParams): APIPromise<Paginated<Shop>> =>
        api.get('shops/', { params }),
      menus: (menuId: PK) => ({
        get: (params: QueryParams) =>
          api.get(`shops/${id}/menus/${menuId}/`, { params }),
        list: (params: QueryParams) =>
          api.get(`shops/${id}/menus/`, { params }),

        reservableTimes: (date: string) => ({
          get: (params?: QueryParams) =>
            api.get(`shops/${id}/menus/${menuId}/reservable_times/${date}/`, {
              params,
            }),
        }),
      }),
      menuOptions: (optionId: PK) => ({
        get: (params: QueryParams) =>
          api.get(`shops/${id}/menu_options/${optionId}/`, { params }),
        list: (params: QueryParams) =>
          api.get(`shops/${id}/menu_options/`, { params }),
      }),
      rejectCount: (date: string) => ({
        get: (): Promise<number> =>
          api.get(`shops/${id}/reject_count/${date}/`),
        inc: (): Promise<number> =>
          api.post(`shops/${id}/reject_count/${date}/inc/`),
        dec: (): Promise<number> =>
          api.post(`shops/${id}/reject_count/${date}/dec/`),
      }),
      monthlyVisitors: (date: string) =>
        api.get(`shops/${id}/monthly_visitors/${date}/`),
    }),
    reservations: (id?: PK) => ({
      get: (params?: QueryParams): APIPromise<Reservation> =>
        api.get(`reservations/${id}/`, { params }),
      list: (params?: QueryParams): APIPromise<Paginated<Reservation>> =>
        api.get(`reservations/`, { params }),
      count: (params?: QueryParams): APIPromise<number> =>
        api.get(`reservations/count/`, { params }),

      createInShop: (data: any) =>
        api.post(`reservations/create_in_shop/`, data),

      create: (data: any): APIPromise<Reservation> =>
        api.post(`reservations/`, data),
      update: (data: any): APIPromise<Reservation> =>
        api.put(`reservations/${id}/`, data),
      partialUpdate: (data: any): APIPromise<Reservation> =>
        api.patch(`reservations/${id}/`, data),

      cancelPrice: (): APIPromise<CancelPrice> =>
        api.get(`reservations/${id}/cancel_price/`),
      cancel: (nocharge?: boolean): APIPromise<Reservation> =>
        api.post(`reservations/${id}/cancel/`, null, { params: { nocharge } }),
      delete: (): APIPromise => api.delete(`reservations/${id}/`),
      restore: (): APIPromise<Reservation> =>
        api.post(`reservations/${id}/restore/`),

      previous: (): APIPromise<null | Reservation> =>
        api.get(`reservations/${id}/previous/`),

      logs: (logId?: PK) => ({
        get: (params?: QueryParams) =>
          api.get(`reservations/${id}/logs/${logId}`, { params }),
        list: (params?: QueryParams) =>
          api.get(`reservations/${id}/logs/`, { params }),
      }),

      sendSmsAppLink: (data: any) =>
        api.post(`reservations/${id}/send_sms_app_link/`, data),
    }),

    kpis: {
      daily: (dateFrom: string, dateTo?: string) =>
        api.get(`kpi/daily_staff_kpis/`, { params: { dateFrom, dateTo } }),
      monthly: (month: string) => api.get(`kpi/monthly_staff_kpis/${month}/`),
      staff: (date: string) => api.get(`kpi/staff_kpis/${date}/`),
    },

    shifts: (id?: PK) => ({
      get: (params: QueryParams) => api.get(`shifts/${id}/`, { params }),
      list: (params: QueryParams) => api.get('shifts/', { params }),
      update: (data: any) => api.patch(`shifts/${id}/`, data),
      count: (params: QueryParams) => api.get('shifts/count/', { params }),
      bulk: (data: any) => api.post(`shifts/bulk/`, data),
    }),

    timelines: ({ shop, date }: { shop: string; date: string }) => ({
      get: (): APIPromise<Timeline> => api.get(`timelines/${shop}/${date}/`),
      availabilities: (params?: {
        menu?: string
        reserve?: string
      }): APIPromise<ReserveAailability[]> =>
        api.get(`timelines/${shop}/${date}/availabilities/`, { params }),
    }),
    my: {
      cards: (id?: number) => ({
        get: (params?: any) => api.get(`my/cards/${id}/`, { params }),
        create: (token: string) => api.post('my/cards/', { card: token }),
        delete: () => api.delete(`my/cards/${id}/`),
        list: (params?: any) => api.get(`my/cards/`, { params }),
      }),
    },
    operationHistories: (id?: PK) => ({
      list: (params: QueryParams): APIPromise<Paginated<OperationHistory>> =>
        api.get(`operation_histories/`, { params }),
      update: (data: any): APIPromise<OperationHistory> =>
        api.put(`operation_histories/${id}/`, data),
      previous: (): APIPromise<OperationHistory> =>
        api.get(`operation_histories/${id}/previous/`),
    }),
    timeCards: (id?: PK) => ({
      list: (params?: QueryParams): APIPromise<Paginated<TimeCard>> =>
        api.get(`timecards/`, { params }),
      get: (): APIPromise<TimeCard> => api.get(`timecards/${id}/`),
      enter: (data: {
        shop: string
        date: string
        staff: string
      }): APIPromise<TimeCard[]> => api.post(`timecards/enter/`, data),
      leave: (): APIPromise<TimeCard[]> => api.post(`timecards/${id}/leave/`),
      delete: (): APIPromise<void> => api.delete(`timecards/${id}/`),
      partialUpdate: (data: any) => api.patch(`timecards/${id}/`, data),
    }),

    informations: (id?: PK) => ({
      list: (params?: QueryParams): APIPromise<Paginated<Information>> =>
        api.get('informations/', { params }),
      get: (params?: QueryParams): APIPromise<Information> =>
        api.get(`informations/${id}/`, { params }),
    }),

    subscriptions: (id?: number) => ({
      list: (params?: any): APIPromise<Paginated<Subscription>> =>
        api.get('subscriptions/', { params }),
      get: (params?: any): APIPromise<Subscription> =>
        api.get(`subscriptions/${id}/`, { params }),

      count: (params?: any): APIPromise<number> =>
        api.get('subscriptions/count/', { params }),
    }),

    reservationLogs: (id?: number) => ({
      list: (params?: any): APIPromise<Paginated<ReservationLog>> =>
        api.get('reservation_logs/', { params }),
      get: (params?: any): APIPromise<ReservationLog> =>
        api.get(`reservation_logs/${id}/`, { params }),
      count: (params?: QueryParams): APIPromise<number> =>
        api.get(`reservation_logs/count/`, { params }),
    }),

    goods: () => ({
      list: (params?: any): APIPromise<Paginated<Goods>> =>
        api.get('goods/', { params }),
    }),

    purchaseHistories: (id?: number) => ({
      list: (params?: any): APIPromise<Paginated<PurchaseHistory>> =>
        api.get('goods/purchase_histories/', { params }),
      count: (params?: any): APIPromise<number> =>
        api.get('goods/purchase_histories/count/', { params }),
      create: (data: any): APIPromise<PurchaseHistory> =>
        api.post('goods/purchase_histories/', data),
      update: (data: any): APIPromise<PurchaseHistory> =>
        api.put(`goods/purchase_histories/${id}/`, data),
      delete: () => api.delete(`goods/purchase_histories/${id}/`),
    }),
    holidays: (id?: number) => ({
      list: (params?: any): APIPromise<Paginated<Holiday>> =>
        api.get('holidays/', { params }),
      get: (params?: any): APIPromise<Holiday> =>
        api.get(`holidays/${id}/`, { params }),
      count: (params?: any): APIPromise<number> =>
        api.get('holidays/count/', { params }),
      bulkUpload: (data: any) => api.post('holidays/bulk/', data),
      delete: () => api.delete(`holidays/${id}/`),
    }),
    openDays: (id?: number) => ({
      list: (params?: any): APIPromise<Paginated<OpenDay>> =>
        api.get('open_days/', { params }),
      get: (params?: any): APIPromise<OpenDay> =>
        api.get(`open_days/${id}/`, { params }),
      count: (params?: any): APIPromise<number> =>
        api.get('open_days/count/', { params }),
      bulkUpload: (data: any) => api.post('open_days/bulk/', data),
      delete: () => api.delete(`open_days/${id}/`),
    }),
    sendWaku: (data: any) => api.post('send_waku/', data),
    forceReservableTimes: (id?: number) => ({
      create: (data: any) => api.post('force_reservable_times/', data),
      delete: () => api.delete(`force_reservable_times/${id}/`),
      list: (params: any) => api.get('force_reservable_times/', { params }),
      count: (params: any) =>
        api.get('force_reservable_times/count/', { params }),
    }),
    timecards: (id?: string) => ({
      get: () => api.get(`timecards/${id}/`),
      list: (params?: any) => api.get('timecards/', { params }),
      count: (params?: any) => api.get('timecards/count/', { params }),
    }),

    getOverflow: (date: string, shop?: null | string) =>
      api.get('overflows/', { params: { date, shop: shop || undefined } }),

    previousOperations: (shop: string, date: string) =>
      api.get(`previous_operations/${shop}/${date}/`),

    firebaseUsers: (id?: string) => ({
      get: () => api.get(`firebase_users/${id}/`),
      update: (data: any) => api.patch(`firebase_users/${id}/`, data),
    }),
  }
}

export type API = ReturnType<typeof createAPI>

export default (getToken: GetTokenFn) => {
  // const API_ENDPOINT = 'http://192.168.1.228:30000/api/2/'
  // process.env.VUE_APP_API_ENDPOINT || 'http://localhost:30000/api/2/'
  const API_ENDPOINT =
    process.env.VUE_APP_API_ENDPOINT || 'http://localhost:30000/api/2/'
  const http = createInstance(getToken, {
    baseURL: API_ENDPOINT,
  })
  console.log('api endpoint', API_ENDPOINT)
  return createAPI(http)
}
