import Vue from 'vue'
import Vuetify from 'vuetify'
import ja from 'vuetify/src/locale/ja'
import { AxiosError } from 'axios'

import moment from 'moment'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import 'vuetify/dist/vuetify.min.css'
import { mask } from 'vue-the-mask'
import VueMeta from 'vue-meta'
import Component from 'vue-class-component'

import App from './App.vue'
import router from './router'
import store from './store'
import createAPI from './api'
import createAPIV3 from './api-v3'

import VirtualDataTable from './virtual-data-table'
import toast from './toast'

declare global {
  interface Window {
    Payjp: any
  }
}
Component.registerHooks(['metaInfo'])

Vue.use(VueMeta, {
  // keyName: 'metaInfo',
  // attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  // tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
})

let locale = window.navigator.language
moment.locale(locale)

Vue.directive('mask', mask)
Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.use(VirtualDataTable)
Vue.use(toast)
const api = createAPI(() => store.getters.token)
const apiv3 = createAPIV3(() => store.getters.token)

Vue.prototype.$api = api
Vue.prototype.$apiV3 = apiv3

store.$api = api

router.beforeEach((to, from, next) => {
  // check login
  if (!store.getters.loggedIn && !['/login', '/auth'].includes(to.path))
    next({ path: '/login', query: { next: to.fullPath } })
  else next()
})

function getValidationErrors(err: AxiosError) {
  // TODO: 401のtokenのexpireに対応
  let response = err.response
  if (response) {
    if (response.status === 400) {
      let data = response.data
      return data
    }
  }
  console.warn(err, response)
  return { nonFieldErrors: ['予期しないエラーが発生しました。'] }
}
Vue.prototype.$getValidationErrors = getValidationErrors

Vue.filter('date', (value: string | null, format = 'YYYY-MM-DD') => {
  if (!value) return value
  return moment(value).format(format)
})
Vue.filter('datetime', (value: string | null, format = 'YYYY-MM-DD HH:mm') => {
  if (!value) return value
  return moment(value).format(format)
})
Vue.filter('time', (value: string | null, format = 'HH:mm') => {
  if (!value) return value
  return moment(value).format(format)
})
Vue.filter('jpy', (value: any) => {
  let num = 0
  if (typeof value === 'string') {
    num = parseInt(value)
  } else if (typeof value === 'number') {
    num = value
  } else {
    return value
  }
  if (isNaN(num)) return value
  return num.toLocaleString('ja-JP') + '円'
})
;(async () => {
  await store.dispatch('refresh')
  new Vue({
    router,
    store,
    vuetify: new Vuetify({
      lang: {
        locales: { ja },
        current: 'ja',
      },
    }),
    render: h => h(App),
  }).$mount('#app')
})()
