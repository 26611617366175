<template>
  <v-app>
    <v-navigation-drawer v-if="superuserLoggedIn" v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title"> cip </v-list-item-title>
          <v-list-item-subtitle> 管理サイト </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="superuserLoggedIn" app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title> 管理 </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-menu v-if="loggedIn" offset-y>
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item :href="shiftURL" target="_blank">
              <v-list-item-content>
                <v-list-item-title> シフト表 </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="shiftPlanURL" target="_blank">
              <v-list-item-content>
                <v-list-item-title> シフト希望入力 </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="djangoAdminURL" target="_blank">
              <v-list-item-content>
                <v-list-item-title> 管理サイト </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item @click="logout">
              <v-list-item-content>
                <v-list-item-title> ログアウト </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'App',
  metaInfo: {
    title: 'fufu管理サイト',
  },
  data: () => ({
    drawer: true,
    items: [
      {
        icon: 'mdi-chart-timeline',
        title: 'タイムライン',
        to: {
          name: 'timeline-shop-selection',
          query: { autoDetectShop: true },
        },
      },
      {
        icon: 'mdi-face-agent',
        title: '顧客管理',
        to: '/customer',
      },
      {
        icon: 'mdi-account',
        title: 'アプリユーザ管理',
        to: '/user',
      },
      {
        icon: 'mdi-clock-outline',
        title: '本日の次回予約',
        to: { name: 'reservation-today', query: { autoDetectShop: true } },
      },
      {
        icon: 'mdi-book-open-variant',
        title: '予約一覧',
        to: {
          name: 'reservation-list',
        },
      },
      {
        icon: 'mdi-history',
        title: '予約変更履歴',
        to: '/logs',
      },
      {
        icon: 'mdi-file-delimited',
        title: '次回予約出力',
        to: '/kpi',
      },
      {
        icon: 'mdi-file-delimited',
        title: '次回予約出力v2',
        to: '/kpi2',
      },
      {
        icon: 'mdi-credit-card',
        title: 'サブスクリプション',
        to: '/subscription',
      },

      {
        icon: 'mdi-calendar',
        title: '営業日設定',
        to: '/openday',
      },
      {
        icon: 'mdi-calendar',
        title: '休業日設定',
        to: '/holiday',
      },
      {
        icon: 'mdi-sync',
        title: 'ホットペッパー連携',
        to: '/send_waku',
      },
      {
        icon: 'mdi-history',
        title: '物販購入履歴',
        to: '/goods/purchase',
      },

      {
        icon: '',
        title: '勤怠',
        to: '/timecard',
      },
      {
        icon: 'mdi-star',
        title: '掛持数',
        to: '/overflow',
      },
      {
        icon: '',
        title: 'シフト',
        to: '/shift',
      },
      {
        icon: 'mdi-palette',
        title: '前回カラー',
        to: { path: '/previous_color', query: { autoDetectShop: true } },
      },
      {
        icon: 'mdi-shop-edit',
        title: 'ECユーザ',
        to: '/ec_user',
      },
      {
        icon: '',
        title: '追加枠',
        to: '/additional_waku',
      },
      {
        icon: 'mdi-account-network',
        title: '名寄',
        to: '/merge_customer',
      },
      {
        icon: '',
        title: 'チケット使用履歴',
        to: '/used_ticket',
      },
      {
        icon: '',
        title: 'チケット購入履歴',
        to: '/purchased_ticket',
      },
      {
        icon: '',
        title: 'Firebaseユーザー',
        to: '/firebase_user',
      },
    ],
  }),
  computed: {
    ...mapGetters(['loggedIn', 'superuserLoggedIn']),
    shiftURL() {
      return process.env.VUE_APP_UGLY_ADMIN_BASE_URL + 'shift'
    },
    shiftPlanURL() {
      return process.env.VUE_APP_UGLY_ADMIN_BASE_URL + 'shift-plan'
    },
    djangoAdminURL() {
      return process.env.VUE_APP_DJANGO_ADMIN_URL
    },
  },
  methods: {
    ...mapActions(['clear']),
    logout() {
      this.clear()
      this.$router.push('/login')
    },
  },
}
</script>

<style></style>
