import axios, { AxiosInstance } from 'axios'

import qs from 'qs'
import { snakeProps } from 'change-prop-case'

function setErrorStatus(error: any) {
  if (error.response) {
    error.status = error.statusCode = error.response.status
  }
  return Promise.reject(error)
}

function createInstance(
  getToken: () => string,
  config: Record<string, unknown>
) {
  let axiosInstance = axios.create(
    Object.assign(
      {
        paramsSerializer: (params: any) =>
          qs.stringify(snakeProps(params), { indices: false }),
      },
      config
    )
  )
  // set token interceptors
  axiosInstance.interceptors.response.use(res => res.data, setErrorStatus)
  axiosInstance.interceptors.request.use(
    config => {
      // NOTE: axios.defaultsを使用すると別ユーザに影響がでるため
      // interceptorでtokenをセットする
      let token = null
      if (typeof getToken === 'function') {
        token = getToken()
      }
      if (!token) return config
      if (token) config.headers.common['Authorization'] = 'JWT ' + token
      return config
    },
    err => Promise.reject(err)
  )
  return axiosInstance
}

const createAPIV3 = (api: AxiosInstance) => {
  return {
    axios,
    http: api,
    counselingQuestions: (id: any) => ({
      get: (params: any) => api.get(`counseling_questions/${id}/`, { params }),
      list: (params: any) => api.get('counseling_questions/', { params }),
    }),
  }
}

export default (getToken: () => string) => {
  // const API_ENDPOINT = 'http://192.168.1.228:30000/api/2/'
  // process.env.VUE_APP_API_ENDPOINT || 'http://localhost:30000/api/2/'
  const API_ENDPOINT =
    process.env.VUE_APP_API_ENDPOINT_V3 || 'http://localhost:30000/api/3/'
  const http = createInstance(getToken, {
    baseURL: API_ENDPOINT,
  })
  console.log('api endpoint', API_ENDPOINT)
  return createAPIV3(http)
}
