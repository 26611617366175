import { VTooltip } from 'vuetify'

export default {
  props: {
    column: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    const col = this.column
    let children = this.renderCell()
    if (col.tooltip && children) {
      return h(
        VTooltip,
        {
          props: {
            attach: true,
            bottom: true,
          },
          scopedSlots: {
            activator: ({ on }) => h('span', { on }, children),
          },
        },
        this.renderCell()
      )
    }
    return h('span', children)
  },
  methods: {
    getContentByProp(prop) {
      const getVal = (obj, key) => {
        let keys = key.split('.')
        let len = keys.length
        for (let idx = 0; idx < len; ++idx) {
          if (!obj) return obj
          if (typeof obj !== 'object') return obj
          obj = obj[keys[idx]]
        }
        return obj
      }
      return getVal(this.row.item, prop)
    },
    renderCell() {
      const col = this.column
      let text = null
      if (col.prop) text = this.getContentByProp(col.prop)
      if (col.render)
        return col.render(Object.assign({ text, value: text }, this.row))
      return text
    },
  },
}
