<template>
  <v-layout align-center class="header-cell" @click="toggleOrder">
    {{ column.text }}
    <template v-if="orderState">
      <v-spacer />
      <v-icon small @click.stop="clearOrder">clear</v-icon>
      <small v-if="orderState.showIndex">{{ orderState.index }}</small>
      <v-icon> arrow_drop_{{ orderState.desc ? 'up' : 'down' }} </v-icon>
    </template>
  </v-layout>
</template>

<script>
export default {
  props: {
    column: {
      type: Object,
      required: true,
    },
    ordering: {
      type: String,
      default: '',
    },
  },
  computed: {
    orderState() {
      let key = this.column.orderField
      if (!key) return null
      return this.parseOrdering().find(x => x.key === key)
    },
  },
  methods: {
    parseOrdering() {
      let o = this.ordering
      let orderings = o ? o.split(',') : []
      let showIndex = orderings.length > 1
      return orderings.map((x, index) => {
        let desc = x.startsWith('-')
        return {
          key: desc ? x.substring(1) : x,
          desc,
          showIndex,
          index: index + 1,
        }
      })
    },
    toggleOrder() {
      let key = this.column.orderField
      if (!key) return
      let orderings = this.parseOrdering()
      let state = orderings.find(x => x.key === key)
      if (!state) {
        orderings.push({ key, desc: false })
      } else if (state.desc) {
        state.key = null
      } else {
        state.desc = true
      }
      this.updateOrder(orderings)
    },
    clearOrder() {
      let key = this.column.orderField
      let orderings = this.parseOrdering()
      let state = orderings.find(x => x.key === key)
      state.key = null
      this.updateOrder(orderings)
    },
    updateOrder(orderings) {
      let val = orderings
        .filter(x => x.key)
        .map(x => (x.desc ? '-' + x.key : x.key))
        .join(',')
      this.$emit('order', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.header-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
