export default {
  inject: ['$_addColumn'],
  // functional: true,
  props: {
    label: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'left',
    },
    width: {
      type: [String, Number],
      default: 200,
    },
    prop: {
      type: String,
      default: null,
    },
    fixed: {
      type: String,
      validate: value => value === 'left' || value === 'right',
      default: null,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    orderField: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    column: null,
  }),
  render(h) {
    return h('div')
  },
  created() {
    // console.log('column created', this.$slots, this.$scopedSlots)
    const props = this.$props
    this.column = {
      text: props.label,
      align: props.align,
      width: parseInt(props.width),
      prop: props.prop,
      tooltip: props.tooltip,
      orderField: props.orderField,
      fixed: props.fixed,
      key: String(Math.random()),
      value: 'id',
      render: null,
    }
    this.$_addColumn(this.column)
  },
  mounted() {
    // console.log('column mounted', this.$slots, this.$scopedSlots)
    // NOTE: scopedSlotsはcreatedのタイミングだと未作成なのでmountedで設定する
    let scopedSlot = this.$scopedSlots.default
    if (scopedSlot) this.column.render = scopedSlot
  },
  beforeUpdate() {
    // slotの更新処理
    // NOTE: slotの変更はwatchで取れないのでbeforeUpdateで処理
    const slot = this.$slots.default
    const scopedSlot = this.$scopedSlots.default
    if (slot || scopedSlot) this.column.render = slot ? () => slot : scopedSlot
    else this.column.render = null
  },
  watch: {
    $props: {
      handler(value) {
        Object.assign(this.column, value)
      },
      deep: true,
    },
  },
}
