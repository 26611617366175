<template>
  <v-container>
    <router-link to="/customer">顧客</router-link>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  beforeRouteEnter(to, from, next) {
    next({ path: '/timeline', query: { autoDetectShop: true } })
  },
}
</script>
