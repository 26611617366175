<template>
  <div
    ref="container"
    :style="{
      height: px(height),
      'max-height': px(height),
      'min-height': px(height),
    }"
    class="fixed-headers-container"
  >
    <div class="wrapper">
      <div
        :style="{
          height: `calc(100% - ${px(hScrollHeight)})`,
          width: px(leftWidth),
        }"
        class="left-container elevation-1"
      >
        <div
          :style="{ height: headerHeight }"
          class="horizontal-header elevation-1"
        >
          <slot name="left-header" />
        </div>
        <div
          ref="leftBody"
          :style="{
            height: `calc(100% - ${headerHeight})`,
          }"
          class="left-body"
        >
          <slot name="left-body" />
        </div>
      </div>

      <div
        ref="middleContainer"
        :style="{
          height: `calc(100% - ${px(hScrollHeight)})`,
          width: `calc(100% - ${px(leftWidth)} - ${px(rightWidth)}`,
        }"
        class="middle-container"
      >
        <div
          :style="{
            height: headerHeight,
            width: px(middleWidth),
          }"
          class="horizontal-header elevation-1"
        >
          <slot name="header" />
        </div>
        <div
          ref="middleBody"
          :style="{
            height: `calc(100% - ${headerHeight})`,
            width: `calc(${px(middleWidth)})`,
          }"
          class="middle-body"
        >
          <slot ref="body" name="body" />
        </div>
      </div>

      <div
        :style="{
          height: `calc(100% - ${px(hScrollHeight)})`,
          width: px(rightWidth),
        }"
        class="right-container elevation-1"
      >
        <div
          :style="{ height: headerHeight }"
          class="horizontal-header elevation-1"
        >
          <slot name="right-header" />
        </div>
        <div
          ref="rightBody"
          :style="{
            height: `calc(100% - ${headerHeight})`,
          }"
          class="right-body"
        >
          <slot name="right-body" />
        </div>
      </div>

      <div
        ref="vscroll"
        :style="{
          width: px(vScrollWidth),
          height: `calc(100% - ${px(hScrollHeight)} - ${headerHeight})`,
          top: headerHeight,
        }"
        class="vertical-scroll"
      >
        <div :style="{ height: px(dataHeight) }" />
      </div>
      <div
        ref="hscroll"
        :style="{
          height: px(hScrollHeight),
          width: `100%`,
        }"
        class="horizontal-scroll"
      >
        <div
          :style="{
            width: `calc(${px(leftWidth)} + ${px(middleWidth)} + ${px(
              rightWidth
            )} + ${px(vScrollWidth)})`,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from 'lodash'
function px(val) {
  return Number(val) + 'px'
}
export default {
  name: 'FixedHeadersContainer',
  props: {
    height: {
      type: [String, Number],
      default: 300,
    },
    leftWidth: {
      type: Number,
      default: 0,
    },
    rightWidth: {
      type: Number,
      default: 0,
    },
    middleWidth: {
      type: Number,
      required: true,
    },
    dataHeight: {
      type: Number,
      required: true,
    },
    headerHeight: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hScrollHeight: 16,
      vScrollWidth: 16,
    }
  },
  mounted() {
    this.$refs.vscroll.addEventListener('scroll', throttle(this.onVScroll, 100))

    let leftBody = this.$refs.leftBody.querySelector('.vue-recycle-scroller')
    if (!leftBody) leftBody = this.$refs.leftBody
    leftBody.addEventListener('scroll', throttle(this.onVScroll, 100))

    let middleBody = this.$refs.middleBody.querySelector(
      '.vue-recycle-scroller'
    )
    if (!middleBody) middleBody = this.$refs.middleBody
    middleBody.addEventListener('scroll', throttle(this.onVScroll, 100))

    let rightBody = this.$refs.rightBody.querySelector('.vue-recycle-scroller')
    if (!rightBody) rightBody = this.$refs.rightBody
    rightBody.addEventListener('scroll', throttle(this.onVScroll, 100))

    // horizontal scroll
    this.$refs.hscroll.addEventListener('scroll', throttle(this.onHScroll, 100))
    this.$refs.middleContainer.addEventListener(
      'scroll',
      throttle(this.onHScroll, 100)
    )
    // this.$refs.container.addEventListener('mousewheel', this.onWheel, false)
  },
  methods: {
    px,
    // onWheel(e) {
    //   // e.preventDefault()
    //   const { deltaX, deltaY } = e
    //   // console.log('mousewheel', deltaX, deltaY)
    //   const hscroll = this.$refs.hscroll
    //   const vscroll = this.$refs.vscroll
    //   const prevLeft = hscroll.scrollLeft
    //   const prevTop = vscroll.scrollTop
    //   hscroll.scrollLeft += deltaX
    //   vscroll.scrollTop += deltaY
    //   if (prevLeft !== hscroll.scrollLeft || prevTop !== vscroll.scrollTop) {
    //     e.preventDefault()
    //   }
    //   // console.log('mousewheel', deltaY, this.$refs.vscroll.scrollTop)
    // },
    onVScroll(e) {
      const { middleBody, leftBody, rightBody, vscroll } = this.$refs
      const scrollTop = e.target.scrollTop
      // console.log('vscroll', e)
      vscroll.scrollTop = scrollTop
      ;[middleBody, leftBody, rightBody].forEach(el => {
        el.scrollTop = scrollTop
        const scroller = el.querySelector('.scroller')
        if (scroller) scroller.scrollTop = scrollTop
      })
    },
    onHScroll(e) {
      const { middleContainer, hscroll } = this.$refs

      if (e.target === middleContainer) {
        hscroll.scrollLeft = middleContainer.scrollLeft
      } else {
        middleContainer.scrollLeft = e.target.scrollLeft
      }
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.fixed-headers-container {
  position: relative;
  .left-container {
    float: left;
    // border-right: 1px solid grey;
    // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    //   0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .middle-container {
    float: left;
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: none;

    // &::-webkit-scrollbar {
    //   width: 0;
    //   background-color: transparent;
    //   scrollbar-width: none;
    // }
    // &::-webkit-scrollbar-track {
    //   // background: transparent;
    //   background-color: transparent;
    //   width: 0;
    //   height: 0;
    // }
    // &::-webkit-scrollbar-thumb {
    //   border-radius: 0;
    // }
  }
  .right-container {
    float: left;
  }
  .left-body {
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .horizontal-header {
    overflow: hidden;
    // border-bottom: 1px solid grey;
  }
  .middle-body {
    overflow-x: hidden;
    overflow-y: scroll;
    // border: 1px solid green;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .horizontal-scroll {
    background-color: transparent;
    clear: both;
    overflow-x: scroll;
    overflow-y: hidden;
    border: none;
    div {
      height: 1px;
    }
    &::-webkit-scrollbar {
      // width: 5px;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 50, 0.5);
      border-radius: 5px;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
    }
  }
  .vertical-scroll {
    background-color: transparent;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    border: none;
    div {
      width: 1px;
    }
    &::-webkit-scrollbar {
      width: 48px;
      // display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 50, 0.5);
      border-radius: 10px;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
    }
  }
}
.wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
